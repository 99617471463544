import { NavLink } from "react-router-dom";

export default function HeaderLinkItems({onNavLinkClick}) {
  return (
    <>
      <NavLink to={"/home"} onClick={onNavLinkClick}
               className={({isActive, isPending}) => isActive ? "header-item-active" : "header-item"}>
        Inicio
      </NavLink>
      <NavLink to={"/who-we-are"} onClick={onNavLinkClick}
               className={({isActive, isPending}) => isActive ? "header-item-active" : "header-item"}>
        ¿Quiénes somos?
      </NavLink>
      <NavLink to={"/existing-projects"} onClick={onNavLinkClick}
               className={({isActive, isPending}) => isActive ? "header-item-active" : "header-item"}>
        Proyectos existentes
      </NavLink>
      <NavLink to={"/find-financing"} onClick={onNavLinkClick}
               className={({isActive, isPending}) => isActive ? "header-item-active" : "header-item"}>
        Encontrar financiación
      </NavLink>
      <NavLink to={"/contact"} onClick={onNavLinkClick}
               className={({isActive, isPending}) => isActive ? "header-item-active" : "header-item"}>
        Contacto
      </NavLink>
    </>
  )
};
