import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';

import { createBrowserRouter, RouterProvider } from "react-router-dom";

import Root from "./routes/root";
import Home from "./components/home/home";
import WhoWeAre from "./components/who-we-are/who-we-are";
import ExistingProjects from "./components/existing-projects/existing-projects";
import FindFinancing from "./components/find-financing/find-financing";
import HealthAndEducation from "./components/health-and-education/health-and-education";
import DigitalMarketingAndSales from "./components/digital-marketing-and-sales/digital-marketing-and-sales";

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';

import './index.css';
import './commons.css';

import "@fontsource/montserrat"; // Defaults to weight 400
import "@fontsource/montserrat/400.css"; // Specify weight
import "@fontsource/montserrat/400-italic.css";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root/>,
    children: [
      {
        children: [
          {
            index: true,
            element: <Home/>
          },
          {
            path: "home",
            element: <Home/>,
          },
          {
            path: "who-we-are",
            element: <WhoWeAre/>,
          },
          {
            path: "existing-projects",
            element: <ExistingProjects/>,
          },
          {
            path: "find-financing",
            element: <FindFinancing/>,
          },
          {
            path: "health-and-education",
            element: <HealthAndEducation/>,
          },
          {
            path: "digital-marketing-and-sales",
            element: <DigitalMarketingAndSales/>,
          },
        ],
      },
    ],
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router}/>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
