import { Image } from "react-bootstrap";

import * as ImageUtils from "../../utils/image-utils"
import * as ScreenUtils from "../../utils/screen-utils";

import "./who-we-are.css";

export default function WhoWeAre() {
  return (
    <div className="background-image-container">
      <div className="scrollable">
        <h1 className={`h-title${ScreenUtils.isMobileEnvironment() ? "-mobile" : ""}`}>¿Quiénes somos?</h1>

        <p className={`p-title${ScreenUtils.isMobileEnvironment() ? "-mobile" : ""} p-title-black`}>
          Lotrán está formado por un grupo de inversionistas de diversas áreas con ideales en común.
        </p>

        <div
          className={`middle-container${ScreenUtils.isMobileEnvironment() ? "-mobile" : ""} wwa-middle-container${ScreenUtils.isMobileEnvironment() ? "-mobile" : ""}`}>
          <div>
            <Image src={ImageUtils.vickyImage} alt="Vicky"/>
            <label className="wwo-label">Victoria Burgueño</label>
            <p className="p-description">Lic. en Psicomotricidad</p>
            <p className="p-description">Magister en Neuropsicología</p>
          </div>
          <div>
            <Image src={ImageUtils.angieImage} alt="Angie"/>
            <label className="wwo-label">Ángela Trias</label>
            <p className="p-description">Community Manager</p>
          </div>
          <div>
            <Image src={ImageUtils.omarImage} alt="Omar"/>
            <label className="wwo-label">Omar Santos</label>
            <p className="p-description">Lic. en Comunicación</p>
          </div>
          <div>
            <Image src={ImageUtils.gonzaImage} alt="Gonza"/>
            <label className="wwo-label">Gonzalo Macedo</label>
            <p className="p-description">Ingeniero en Sistemas</p>
          </div>
        </div>
      </div>
    </div>
  );
}
