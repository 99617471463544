import MyClinicByLotranGroupImage from "../assets/images/my-clinic-by-lotran-group-label.png"

import VickyImage from "../assets/images/photos/vicky-min.png"
import AngieImage from "../assets/images/photos/angie-min.png"
import OmarImage from "../assets/images/photos/omar-min.png"
import GonzaImage from "../assets/images/photos/gonza-min.png"

import LotranGroupLabel from "../assets/images/lotran-group-label.png"
import BalloonImage from "../assets/images/balloon.svg"

import FinancingImage from "../assets/images/financing.png"

export const myClinicByLotranGroupImage = MyClinicByLotranGroupImage

export const vickyImage = VickyImage
export const angieImage = AngieImage
export const omarImage = OmarImage
export const gonzaImage = GonzaImage

export const lotranGroupLabel = LotranGroupLabel
export const balloonImage = BalloonImage

export const financingImage = FinancingImage
