import React, { useState } from "react";

import { Outlet, NavLink, useNavigation } from "react-router-dom";

import { Image, Nav, Offcanvas } from "react-bootstrap";

import HeaderLinkItems from "../header-link-items"

import "../root.css";
import "./mobile.css";
import * as ImageUtils from "../../utils/image-utils";
import FooterLinkItems from "../footer-link-items";

export default function Mobile() {

  // Off canvas
  const [showOffCanvas, setShowOffCanvas] = useState(false);
  const handleCloseOffCanvas = () => setShowOffCanvas(false);

  const navigation = useNavigation();

  function doShowOffCanvas() {
    setShowOffCanvas(true);
  }

  function doHideOffCanvas() {
    setShowOffCanvas(false);
  }

  return (
    <>
      <button className="image-button menu-btn" onClick={doShowOffCanvas}>
        <i className="bi bi-list"/>
      </button>

      <Outlet/>

      <Offcanvas show={showOffCanvas} onHide={handleCloseOffCanvas} placement="start" className="root-off-canvas">
        <Offcanvas.Body>
          <>
            <div className="header-links-container">
              <NavLink to="/home" onClick={doHideOffCanvas}>
                <Image className="header-image" src={ImageUtils.lotranGroupLabel} width="100%" alt="Lotran group" />
              </NavLink>
              <hr/>
              <HeaderLinkItems onNavLinkClick={doHideOffCanvas}/>
            </div>
            <div className="footer-links-container">
              <hr/>
              <FooterLinkItems onNavLinkClick={doHideOffCanvas}/>
            </div>
          </>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};
