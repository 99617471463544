import { Image } from "react-bootstrap";

import * as ImageUtils from "../../utils/image-utils"
import * as ScreenUtils from "../../utils/screen-utils";

import "./health-and-education.css";

export default function HealthAndEducation() {
  return (
    <div className="background-image-container">
      <div className="scrollable">
        <h1 className={`h-title${ScreenUtils.isMobileEnvironment() ? "-mobile" : ""}`}>SALUD - EDUCACIÓN</h1>

        <p className={`p-title${ScreenUtils.isMobileEnvironment() ? "-mobile" : ""} p-title-black p-title-justify`}>
          Estos son algunos de los proyectos que hemos desarrollado en los cuales puedes invertir adquiriendo parte de
          ellos a un precio ajustado a tus posibilidades.
        </p>

        {ScreenUtils.isMobileEnvironment() && (<hr className="mobile-separator"/>)}

        <div className="container">
          <div className={`clinic-container${ScreenUtils.isMobileEnvironment() ? "-mobile" : ""}`}>
            <a href="https://www.myclinic.lotrangroup.com/" target="_blank" rel="noopener noreferrer">
              <Image className={`clinic-container-image${ScreenUtils.isMobileEnvironment() ? "-mobile" : ""}`}
                     src={ImageUtils.myClinicByLotranGroupImage}/>
            </a>
            <p className={`clinic-container-p${ScreenUtils.isMobileEnvironment() ? "-mobile" : ""}`}>
              <label className="clinic-container-label">My Clinic</label> es una plataforma que permite gestionar de
              forma sencilla e intuitiva información primordial para tu institución, logrando integrar datos
              médico/clínico en un solo lugar. Además, está diseñada para adaptarse a las necesidades específicas de
              cada empresa, teniendo como finalidad minimizar los tiempos de trabajo y potenciar al máximo los
              estándares de calidad de servicio.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
