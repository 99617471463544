import * as ScreenUtils from "../../utils/screen-utils";

import "./home.css";

export default function Home() {
  return (
    <div className="home-background-image-container">
      <div className="scrollable background-image-div-container">
        <p className="p-title p-monospace">
          Desarrollamos y llevamos a cabo diversos proyectos vinculados a diferentes áreas, desde una mirada integral y
          holística realizada por nuestro equipo de trabajo.
        </p>

        {ScreenUtils.isMobileEnvironment() && (<hr className="mobile-separator"/>)}

        <div
          className={`middle-container${ScreenUtils.isMobileEnvironment() ? "-mobile" : ""} home-middle-container${ScreenUtils.isMobileEnvironment() ? "-mobile" : ""}`}>
          <div className="middle-container-one">
            <p>
              NUESTRAS
              <br/>
              PROPUESTAS
            </p>
          </div>
          <div className="middle-container-two">
            <label className="title">Invierte en nuestros proyectos</label>
            <p>
              Somos un grupo inversionista el cual busca canalizar tus inversiones en nuestros proyectos, para que
              puedas adquirir un ingreso de forma pasiva a largo plazo.
            </p>
          </div>
          <div className={`middle-container-three${ScreenUtils.isMobileEnvironment() ? "-mobile" : ""}`}>
            <label className="title">Encontrar financiación</label>
            <p>
              Si tienes un proyecto en mente y quieres conseguir ingresos para llevarlo a cabo, no dudes en
              consultarnos.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
