import { NavLink } from "react-router-dom";

import { Button, Image } from "react-bootstrap";

import * as ImageUtils from "../../utils/image-utils"
import * as ScreenUtils from "../../utils/screen-utils";

import "./existing-projects.css";

export default function ExistingProjects() {
  return (
    <div className="background-image-container">
      <div className="scrollable">
        <h1 className={`h-title${ScreenUtils.isMobileEnvironment() ? "-mobile" : ""}`}>Invertir en proyectos
          existentes</h1>

        <p className={`p-title${ScreenUtils.isMobileEnvironment() ? "-mobile" : ""} p-title-black ep-p-title`}>
          Somos un grupo inversionista el cual busca canalizar tus inversiones en nuestros proyectos, para que puedas
          adquirir un ingreso de forma pasiva a largo plazo.
        </p>
        <p className={`p-title${ScreenUtils.isMobileEnvironment() ? "-mobile" : ""} p-title-black ep-p-title`}>
          <label className="ep-label">Lotrán Group</label> está conformado por diferentes departamentos
          integrados cada uno de ellos por una amplia diversidad de productos en los cuales puedes invertir.
        </p>

        <h2 className={`h2-title${ScreenUtils.isMobileEnvironment() ? "-mobile" : ""}`}>Departamentos</h2>

        <div
          className={`middle-container${ScreenUtils.isMobileEnvironment() ? "-mobile" : ""} ep-middle-container${ScreenUtils.isMobileEnvironment() ? "-mobile" : ""}`}>
          <div className="projects-container">
            <Button
              className={`btn btn-primary ep-button ${ScreenUtils.isMobileEnvironment() ? "ep-button-mobile" : ""}`}>
              <NavLink className="nav-link" to="/health-and-education">salud - educación</NavLink>
            </Button>
            <Button
              className={`btn btn-primary ep-button ${ScreenUtils.isMobileEnvironment() ? "ep-button-mobile" : ""}`}>
              <NavLink className="nav-link" to="/digital-marketing-and-sales">
                marketing digital y ventas
              </NavLink>
            </Button>
            <a className="button-link" href="https://instagram.com/lotranimportaciones?igshid=MzRlODBiNWFlZA=="
               target="_blank" rel="noopener noreferrer">
              <Button
                className={`btn btn-primary ep-button ${ScreenUtils.isMobileEnvironment() ? "ep-button-mobile" : ""}`}>
                importación de productos
              </Button>
            </a>
            {/*<Button type="button" class="btn btn-primary button" href="https://instagram.com/lotranimportaciones?igshid=MzRlODBiNWFlZA==">importación de productos</Button>*/}
          </div>

          <div>
            <Button
              className={`btn btn-primary image-button ${ScreenUtils.isMobileEnvironment() ? "consult-button-mobile" : ""}`}
              data-bs-toggle="modal" data-bs-target="#sendEmailModal">
              <Image src={ImageUtils.balloonImage} alt="consultar" width="70%" height="70%"/>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
