import { Outlet } from "react-router-dom";

import { Image } from "react-bootstrap";

import * as ImageUtils from "../../utils/image-utils"

import HeaderLinkItems from "../header-link-items";
import FooterLinkItems from "../footer-link-items";

import "../root.css";
import "./desktop.css";

const Desktop = () => (
  <>
    <div className="header-container">
      <a className="navbar-brand" href="/home">
        <Image className="header-image" src={ImageUtils.lotranGroupLabel} height="40px" alt="Lotran group"/>
      </a>
      <div className="end-container">
        <HeaderLinkItems/>
      </div>
    </div>

    <Outlet className="outlet-container"/>

    <div className="footer-container">
      <FooterLinkItems/>
    </div>
  </>
);

export default Desktop;
