import { Button, Image } from "react-bootstrap";

import * as ImageUtils from "../../utils/image-utils"
import * as ScreenUtils from "../../utils/screen-utils";

import "./find-financing.css";

export default function FindFinancing() {
  return (
    <div className="background-image-container">
      <div className="scrollable">
        <h1 className={`h-title${ScreenUtils.isMobileEnvironment() ? "-mobile" : ""}`}>Encontrar financiación</h1>

        <p className={`p-title${ScreenUtils.isMobileEnvironment() ? "-mobile" : ""} p-title-black ff-p-title`}>
          Si tienes un proyecto en mente y quieres conseguir ingresos para llevarlo a cabo, no dudes en consultarnos.
        </p>
        <p className={`p-title${ScreenUtils.isMobileEnvironment() ? "-mobile" : ""} p-title-black ff-p-title`}>
          Te brindamos la ayuda económica necesaria para iniciar y desarrollar tus ideas, proyectos, emprendimientos.
        </p>

        <div className={`images-container${ScreenUtils.isMobileEnvironment() ? "-mobile" : ""}`}>
          <Image src={ImageUtils.financingImage} alt="consultar"
                 width={`${ScreenUtils.isMobileEnvironment() ? "100" : "50"}%`}
                 height={`${ScreenUtils.isMobileEnvironment() ? "100" : "50"}%`}/>
          <Button type="button" className="btn btn-primary image-button" data-bs-toggle="modal"
                  data-bs-target="#sendEmailModal">
            <Image src={ImageUtils.balloonImage} alt="consultar" width="50%" height="50%"/>
          </Button>

        </div>
      </div>
    </div>
  );
}
