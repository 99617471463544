import * as ScreenUtils from "../utils/screen-utils";

import Mobile from "./mobile/mobile";
import Desktop from "./desktop/desktop"

import "./root.css"

export default function Root() {
  ScreenUtils.setActualWidthScreen(window.innerWidth);

  return (
    <>
      {ScreenUtils.isMobileEnvironment() ? (
        <Mobile/>
      ) : (
        <Desktop/>
      )}
    </>
  );
}
