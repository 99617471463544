import * as ScreenUtils from "../../utils/screen-utils";

import "./digital-marketing-and-sales.css";

export default function DigitalMarketingAndSales() {
  return (
    <div className="background-image-container">
      <div className="scrollable">
        <h1 className={`h-title${ScreenUtils.isMobileEnvironment() ? "-mobile" : ""}`}>MARKETING DIGITAL Y VENTAS</h1>

        <p
          className={`p-title${ScreenUtils.isMobileEnvironment() ? "-mobile" : ""} p-title-black p-title-justify dmas-first-p-title`}>
          El marketing digital y las ventas están estrechamente relacionados, ya que el marketing digital puede ser una
          herramienta poderosa para generar leads, aumentar la visibilidad de una marca y, en última instancia, impulsar
          las ventas.
        </p>
        <p className={`p-title${ScreenUtils.isMobileEnvironment() ? "-mobile" : ""} p-title-black p-title-justify`}>
          Contamos con un equipo altamente capacitado para potenciar tu marca y brindarte todas las herramientas
          necesarias para alcanzar tus objetivos.
        </p>
      </div>
    </div>
  );
}
